import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, Signal } from '@angular/core';
import { AbstractModal, ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { IDatepickerConfig, IModalInput, IModalOutput } from '@nesea/ngx-ui-kit/shared';
import { IAbsenceTimesheet, IOrderTimesheet, IWorkingDayTimesheet } from '@shared/models/interfaces/timesheet.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IWeekWorktime } from '@shared/models/interfaces/week-worktime.interface';
import { IHoliday } from '@shared/models/interfaces/holiday.interface';
import { DateUtils } from '@shared/utils/date.utils';
import { TimesheetUtils } from '@shared/utils/timesheet.utils';

export interface ITimesheetOrderDeleteModalInput extends IModalInput {
  userId: number;
  currentMonth: number;
  currentYear: number;
  workTime: IWeekWorktime[];
  holidays: IHoliday[];
  workingDays: IWorkingDayTimesheet<IOrderTimesheet>[];
  absences: IAbsenceTimesheet[];
}
export interface ITimesheetOrderDeleteModalOutput extends IModalOutput {
  outcome?: boolean;
  workingDays: IWorkingDayTimesheet<Partial<IOrderTimesheet>>[];
}

@Component({
  selector: 'nsf-timesheet-order-delete-modal',
  templateUrl: './timesheet-order-delete-modal.component.html',
  styleUrl: './timesheet-order-delete-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimesheetOrderDeleteModalComponent extends AbstractModal<ITimesheetOrderDeleteModalInput, ITimesheetOrderDeleteModalOutput> {

  startDateConfig: IDatepickerConfig;
  endDateConfig: IDatepickerConfig;

  form: FormGroup;

  userOrders: Partial<IOrderTimesheet>[] = [];

  startDate: Signal<Date> = computed(() => this.form?.get('startDate')?.getRawValue() as Date);
  currentDate: Signal<Date> = computed(() => new Date(this.data.currentYear, this.data.currentMonth, 1));

  constructor(
    protected override modalViewerService: ModalViewerService,
    private _fb: FormBuilder,
    private _cdr: ChangeDetectorRef
  ) {
    super(modalViewerService);
  }

  get multipleDays(): boolean {
    return !!this.form.get('showEndDate').getRawValue()
  }

  override onInit(): void {
    this.startDateConfig = {
      adaptivePosition: true,
      minDate: DateUtils.firstDayOfMonth(this.currentDate()),
      maxDate: DateUtils.lastDayOfMonth(this.currentDate()),
      disableWeekends: true,
      daysDisabled: this._getDisabledDays()
    };

    this.endDateConfig = {
      adaptivePosition: true,
      maxDate: DateUtils.lastDayOfMonth(this.currentDate()),
      disableWeekends: true,
      daysDisabled: this._getDisabledDays()
    };

    this.form = this._fb.group({
      startDate: this._fb.control(null, Validators.required),
      endDate: this._fb.control({ value: null, disabled: true }, Validators.required),
      showEndDate: this._fb.control({ value: false, disabled: true }),
      orderName: this._fb.control({ value: null, disabled: true }, Validators.required),
      customer: this._fb.control({ value: null, disabled: true })
    });
  }

  override onDestroy(): void {}

  compareOrderOptionsFn = (option: number, value: number): boolean => option === value;

  onStartDateChange(value: Date): void {
    this.form.get('endDate').reset();
    this.form.get('endDate').disable();

    this.form.get('showEndDate').setValue(false);

    if(!!value) {
      this.form.get('showEndDate').enable();
      this.form.get('endDate').setValue(value);
    } else {
      this.form.get('showEndDate').disable();
    }

    this.form.get('orderName').reset();
    this.form.get('orderName').disable();
    this.form.get('customer').reset();

    this._retrieveUserOrders();
  }

  onEndDateChange(value: Date): void {
    this.form.get('orderName').reset();
    this.form.get('orderName').disable();
    this.form.get('customer').reset();

    if(!!value) {
      this._retrieveUserOrders();
    }
  }

  onShowEndDateChange(value: boolean): void {
    this.endDateConfig = {
      adaptivePosition: true,
      maxDate: DateUtils.lastDayOfMonth(this.currentDate()),
      disableWeekends: true,
      daysDisabled: this._getDisabledDays()
    };

    this.form.get('orderName').reset();
    this.form.get('orderName').disable();
    this.form.get('customer').reset();
    this.userOrders = [];

    if(!!value) {
      this.form.get('endDate').reset();
      this.form.get('endDate').enable();
      this.endDateConfig = {
        ...this.endDateConfig,
        minDate: !!this.form.get('startDate').value ? DateUtils.addDays(this.form.get('startDate').value, 1) : undefined
      };
    } else {
      this.form.get('endDate').disable();
      this.form.get('endDate').setValue(this.form.get('startDate').value);

      this._retrieveUserOrders();
    }
  }

  onOrderChange(value: number): void {
    if(!value) {
      this.form.get('customer').reset();
    } else this.form.get('customer').setValue(this.userOrders.find(({ id }) => id === value).clientName);
  }

  onAbort(): void {
    this.close();
  }

  onConfirm(): void {
    if(this.form.invalid || !this.userOrders?.length) {
      this.form.markAllAsTouched();
    } else {
      const { startDate, endDate, orderName: orderId } = this.form.getRawValue();

      const newWorkingDays: IWorkingDayTimesheet<IOrderTimesheet>[] = this.data.workingDays
        .filter(workingDay => workingDay.commesseOreTimesheet.some(order => order.id === orderId))
        .filter(workingDay => DateUtils.daysBetween(startDate, endDate).some(({ dayOfMonth }) => dayOfMonth === workingDay.giorno))
        .map(workingDay => ({
          ...workingDay,
          commesseOreTimesheet: (workingDay.commesseOreTimesheet || []).filter(order => order.id !== orderId)
        }));

      this.close({
        outcome: true,
        workingDays: [...newWorkingDays]
      });
    }
  }

  private _retrieveUserOrders(): void {
    const { startDate, endDate } = this.form.getRawValue();
    const userOrders: IOrderTimesheet[] = [];

    this.data.workingDays
      .filter(workingDay => DateUtils.daysBetween(startDate, endDate).some(({ dayOfMonth }) => dayOfMonth === workingDay.giorno))
      .forEach(workingDay => {
      (workingDay.commesseOreTimesheet || []).forEach(userOrder => {
        if(!userOrders.some(({ id }) => userOrder.id === id)) {
          userOrders.push(userOrder);
        }
      });
    });

    this.userOrders = [...userOrders];

    if(!!this.userOrders?.length) {
      this.form.get('orderName').enable();
    } else {
      this.form.get('orderName').reset();
      this.form.get('orderName').disable();
      this.form.get('customer').reset();
    }

    this._cdr.markForCheck();
  }

  private _getDisabledDays(): Date[] {
    const holidays: Date[] = (this.data.holidays || []).map(({ giorno }) => new Date(this.data.currentYear, this.data.currentMonth, giorno));
    const noWorkingDays: Date[] = this._getNoWorkingDays();
    return [...holidays, ...noWorkingDays];
  }

  private _getNoWorkingDays(): Date[] {
    const currentDate: Date = new Date(this.data.currentYear, this.data.currentMonth, 1);

    const firstDateOfMonth: Date = DateUtils.firstDayOfMonth(currentDate);
    const lastDateOfMonth: Date = DateUtils.lastDayOfMonth(currentDate);

    const output: Date[] = [];
    DateUtils.daysBetween(firstDateOfMonth, lastDateOfMonth).forEach(({ dayOfMonth, dayOfWeek, date }) => {
      const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, date);

      const maxHours: number = workTime?.[dayOfWeek] || 0;
      const absenceHours: number = this.data.absences
        .filter(({ giorno }) => giorno === dayOfMonth)
        .reduce((output, current) => output + current.ore, 0);
      const leftHours: number = maxHours - absenceHours;

      if(leftHours <= 0) output.push(new Date(date));
    });

    return output;
  }

}
