import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, Signal } from '@angular/core';
import { AbstractModal, ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { IDatepickerConfig, IModalInput, IModalOutput } from '@nesea/ngx-ui-kit/shared';
import { IWeekWorktime } from '@shared/models/interfaces/week-worktime.interface';
import { IHoliday } from '@shared/models/interfaces/holiday.interface';
import { IAbsenceTimesheet, IActivityTimesheet, IOrderTimesheet, IOvertimeTimesheet, IWorkingDayTimesheet } from '@shared/models/interfaces/timesheet.interface';
import { DateUtils } from '@shared/utils/date.utils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimesheetUtils } from '@shared/utils/timesheet.utils';
import { OrderTypeEnum } from '@shared/enums/order-type.enum';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { OrdersService } from '@shared/services/orders.service';
import { IOrder } from '@shared/models/interfaces/order.interface';
import { ITypology, ITypologyIT } from '@shared/models/interfaces/typology.interface';
import { OrderActivityTypeEnum, OrderActivityTypeIdEnum } from '@shared/enums/order-activity-type.enum';
import { FundedActivityTypeEnum } from '@shared/enums/funded-activity-type.enum';
import { translate } from '@jsverse/transloco';
import { IFundedWorkingDayTimesheet } from '@shared/models/interfaces/funded-timesheet.interface';

export interface IFundedTimesheetOrderUpdateModalInput extends IModalInput {
  userId: number;
  currentMonth: number;
  currentYear: number;
  orderActivityTypeOptions: ITypologyIT<OrderActivityTypeEnum, OrderActivityTypeIdEnum>[];
  workTime: IWeekWorktime[];
  holidays: IHoliday[];
  workingDays: IWorkingDayTimesheet<IOrderTimesheet>[];
  overtimes: IWorkingDayTimesheet<IOvertimeTimesheet>[];
  absences: IAbsenceTimesheet[];
  fundedWorkingDays: IFundedWorkingDayTimesheet[];
  startDate?: Date;
  order?: IOrderTimesheet;
  activity?: IActivityTimesheet;
}

export interface IFundedTimesheetOrderUpdateModalOutput extends IModalOutput {
  outcome?: boolean;
  remove?: boolean;
  workingDays: IFundedWorkingDayTimesheet[];
  excludedDays?: number[];
  isOrder: boolean;
}

@Component({
  selector: 'nsf-funded-timesheet-order-update-modal',
  templateUrl: './funded-timesheet-order-update-modal.component.html',
  styleUrl: './funded-timesheet-order-update-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundedTimesheetOrderUpdateModalComponent extends AbstractModal<IFundedTimesheetOrderUpdateModalInput, IFundedTimesheetOrderUpdateModalOutput> {

  startDateConfig: IDatepickerConfig;
  endDateConfig: IDatepickerConfig;

  form: FormGroup;

  typeOptions: ITypology<FundedActivityTypeEnum>[] = [];
  userOrders: Partial<IOrder>[] = [];

  startDate: Signal<Date> = computed(() => this.form?.get('startDate')?.getRawValue() as Date);
  currentDate: Signal<Date> = computed(() => new Date(this.data.currentYear, this.data.currentMonth, 1));

  private _fb: FormBuilder = inject(FormBuilder);
  private _cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private _orderService: OrdersService = inject(OrdersService);

  constructor(
    protected override modalViewerService: ModalViewerService
  ) {
    super(modalViewerService);
  }

  get isEdit(): boolean {
    return !!this.data.order || !!this.data.activity;
  }

  get multipleDays(): boolean {
    return !!this.form.get('showEndDate').getRawValue();
  }

  get isOrder(): boolean {
    return this.form.get('type').getRawValue() === FundedActivityTypeEnum.ORDER;
  }

  get isActivity(): boolean {
    return this.form.get('type').getRawValue() === FundedActivityTypeEnum.ACTIVITY;
  }

  override onInit(): void {
    this.typeOptions = [
      {
        id: 1,
        name: translate(`LABEL.${FundedActivityTypeEnum.ORDER}.TEXT`),
        code: FundedActivityTypeEnum.ORDER,
        description: translate(`LABEL.${FundedActivityTypeEnum.ORDER}.TEXT`)
      },
      {
        id: 2,
        name: translate(`LABEL.${FundedActivityTypeEnum.ACTIVITY}.TEXT`),
        code: FundedActivityTypeEnum.ACTIVITY,
        description: translate(`LABEL.${FundedActivityTypeEnum.ACTIVITY}.TEXT`)
      }
    ];

    this.startDateConfig = {
      adaptivePosition: true,
      minDate: DateUtils.firstDayOfMonth(this.currentDate()),
      maxDate: DateUtils.lastDayOfMonth(this.currentDate()),
      disableWeekends: true,
      daysDisabled: this._getDisabledDays()
    };

    this.endDateConfig = {
      adaptivePosition: true,
      maxDate: DateUtils.lastDayOfMonth(this.currentDate()),
      disableWeekends: true,
      daysDisabled: this._getDisabledDays()
    };

    this.form = this._fb.group({
      startDate: this._fb.control({ value: this.data.startDate, disabled: !!this.data.startDate }, Validators.required),
      endDate: this._fb.control({ value: this.data.startDate, disabled: true }, Validators.required),
      showEndDate: this._fb.control({ value: false, disabled: !this.data.startDate || (!!this.data.startDate && DateUtils.sameDate(this.data.startDate, DateUtils.lastDayOfMonth(this.data.startDate))) || !!this.data.order || !!this.data.activity }),
      type: this._fb.control({ value: null, disabled: true }, Validators.required),
      orderId: this._fb.control({ value: null, disabled: true }, Validators.required),
      orderActivityId: this._fb.control({ value: null, disabled: true }, Validators.required),
      hours: this._fb.control(null)
    });

    if(!!this.data.order || !!this.data.activity) {
      const type: FundedActivityTypeEnum = !!this.data.order ? FundedActivityTypeEnum.ORDER : FundedActivityTypeEnum.ACTIVITY;
      this.form.get('type').enable();
      this.form.get('type').setValue(type);

      const start: number = this.data.startDate.getTime();
      const end: number = DateUtils.endOfDay(this.data.startDate).getTime();

      forkJoin([
        this._orderService.getUserOrders(this.data.userId, start, end).pipe(
          catchError(() => of([]))
        ),
        this._orderService.getNonAccountableUserOrders(this.data.userId, start, end).pipe(
          catchError(() => of([]))
        ),
      ]).pipe(
        tap(([orders, nonAccountableOrders]) => {
          this.userOrders = [...this.userOrders]
            .concat(
              [...orders].filter(({ tipologiaCommessa, flagDistacco }) => [OrderTypeEnum.FUNDED_PROJECT, OrderTypeEnum.FUNDED_TRAINING].includes(tipologiaCommessa.codice) && !flagDistacco),
              [...nonAccountableOrders].filter(({ tipologiaCommessa, flagDistacco }) => [OrderTypeEnum.FUNDED_PROJECT, OrderTypeEnum.FUNDED_TRAINING].includes(tipologiaCommessa.codice) && !flagDistacco)
            );

          if(this.isOrder) {
            this.form.get('orderId').enable();
            this.form.get('orderId').setValue(this.data.order.id);
            this.form.get('hours').setValue(this.data.order.oreLavorate);
          } else {
            this.form.get('orderActivityId').enable();
            this.form.get('orderActivityId').setValue(this.data.activity.id);
            this.form.get('hours').setValue(this.data.activity.oreLavorate);
          }

          this._cdr.markForCheck();
        })
      ).subscribe();
    } else {
      this._retrieveUserOrders();
    }
  }

  override onDestroy(): void {}

  compareTypeOptionsFn = (option: FundedActivityTypeEnum, value: FundedActivityTypeEnum): boolean => option === value;

  compareOrderOptionsFn = (option: number, value: number): boolean => option === value;

  compareOrderActivityTypeOptionsFn = (option: number, value: number): boolean => option === value;

  onShowEndDateChange(value: boolean): void {
    this.endDateConfig = {
      adaptivePosition: true,
      maxDate: DateUtils.lastDayOfMonth(this.currentDate()),
      disableWeekends: true,
      daysDisabled: this._getDisabledDays()
    };

    this.form.get('type').reset();
    this.form.get('type').disable();

    this.form.get('orderId').reset();
    this.form.get('orderId').disable();
    this.form.get('orderActivityId').reset();
    this.form.get('orderActivityId').disable();

    this.form.get('hours').reset();

    if(!!value) {
      this.form.get('endDate').reset();
      this.form.get('endDate').enable();
      this.endDateConfig = {
        ...this.endDateConfig,
        minDate: !!this.form.get('startDate').value ? DateUtils.addDays(this.form.get('startDate').value, 1) : undefined
      };
    } else {
      this.form.get('endDate').disable();
      this.form.get('endDate').setValue(this.form.get('startDate').value);

      this._retrieveUserOrders();
    }
  }

  onStartDateChange(value: Date): void {
    this.form.get('endDate').reset();
    this.form.get('endDate').disable();

    this.form.get('showEndDate').setValue(false);

    if(!!value) {
      this.form.get('showEndDate').enable();
      this.form.get('endDate').setValue(value);
    } else {
      this.form.get('showEndDate').disable();
    }

    this.form.get('type').reset();
    this.form.get('type').disable();

    this.form.get('orderId').reset();
    this.form.get('orderId').disable();
    this.form.get('orderActivityId').reset();
    this.form.get('orderActivityId').disable();

    this.form.get('hours').reset();

    this._retrieveUserOrders();
  }

  onEndDateChange(value: Date): void {
    this.form.get('type').reset();
    this.form.get('type').disable();

    this.form.get('orderId').reset();
    this.form.get('orderId').disable();
    this.form.get('orderActivityId').reset();
    this.form.get('orderActivityId').disable();

    this.form.get('hours').reset();

    if(!!value) {
      this._retrieveUserOrders();
    }
  }

  onTypeChange(value: FundedActivityTypeEnum): void {
    this.form.get('orderId').reset();
    this.form.get('orderActivityId').reset();
    this.form.get('hours').reset();

    if(!value) {
      this.form.get('orderId').disable();
      this.form.get('orderActivityId').disable();
    } else if(value === FundedActivityTypeEnum.ORDER) {
      this.form.get('orderId').enable();
      this.form.get('orderActivityId').disable();
    } else {
      this.form.get('orderId').disable();
      this.form.get('orderActivityId').enable();
    }
  }

  onOrderChange(value: number): void {
    if(!value) {
      this.form.get('hours').reset();
    } else {
      if(!this.multipleDays) {
        const { startDate } = this.form.getRawValue();
        const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, new Date(startDate));
        const maxHours: number = workTime?.[(startDate as Date).getDay()] || 0;
        this.form.get('hours').setValue(maxHours);
      }
    }
  }

  onOrderActivityChange(value: number): void {
    if(!value) {
      this.form.get('hours').reset();
    } else {
      if(!this.multipleDays) {
        const { startDate } = this.form.getRawValue();
        const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, new Date(startDate));
        const maxHours: number = workTime?.[(startDate as Date).getDay()] || 0;
        this.form.get('hours').setValue(maxHours);
      }
    }
  }

  onRemove(): void {
    this.close({
      outcome: true,
      remove: true,
      workingDays: [
        {
          giorno: this.startDate().getDate(),
          commessa: undefined,
          attivita: undefined
        }
      ],
      isOrder: this.isOrder
    });
  }

  onAbort(): void {
    this.close();
  }

  onConfirm(): void {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      if(!!this.multipleDays) {
        const { startDate, endDate }: { startDate: Date, endDate: Date } = this.form.getRawValue();
        const daysBetween: { dayOfMonth: number, dayOfWeek: number, date: Date }[] = DateUtils.daysBetween(startDate, endDate);

        const includedDays: { dayOfMonth: number, dayOfWeek: number, date: Date }[] = [];
        const excludedDays: number[] = [];

        daysBetween
          .filter(({ dayOfMonth }) => !this.data.holidays.some(({ giorno }) => giorno === dayOfMonth))
          .filter(({ dayOfMonth }) => {
            const orders: IWorkingDayTimesheet<IOrderTimesheet> = this.data.workingDays.find(({ giorno }) => giorno === dayOfMonth);
            return (orders?.commesseOreTimesheet || []).every(({ code, distacco }) => ![OrderTypeEnum.FUNDED_PROJECT, OrderTypeEnum.FUNDED_TRAINING].includes(code) && !distacco);
          })
          .filter(({ dayOfMonth }) => {
            const overtimeDay: IWorkingDayTimesheet<IOvertimeTimesheet> = this.data.overtimes.find(({ giorno }) => giorno === dayOfMonth);
            return !overtimeDay?.commesseOreTimesheet?.length;
          })
          .filter(({ dayOfMonth }) => !this.data.absences.some(({ giorno }) => giorno === dayOfMonth))
          .forEach(({ dayOfWeek, dayOfMonth, date }) => {
            const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, date);
            const maxHours: number = workTime?.[dayOfWeek] || 0;

            // Giorno non previsto nel contratto
            if(!maxHours) {
              excludedDays.push(dayOfMonth);
            } else {
              const fundedWorkingDay: IFundedWorkingDayTimesheet = this.data.fundedWorkingDays
                .find(({ giorno }) => giorno === dayOfMonth);
              const fundedWorkedDay: boolean = !!fundedWorkingDay?.commessa?.oreLavorate || !!fundedWorkingDay?.attivita?.oreLavorate;

              if(fundedWorkedDay) {
                excludedDays.push(dayOfMonth);
              } else {
                includedDays.push({
                  dayOfMonth,
                  dayOfWeek,
                  date
                });
              }
            }
        });

        const newWorkingDays: IFundedWorkingDayTimesheet[] = [];
        includedDays.forEach(({ dayOfMonth, dayOfWeek, date }) => {
          const { orderId, orderActivityId } = this.form.getRawValue();
          const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, date);
          const hours: number = workTime?.[dayOfWeek] || 0;
          let order: IOrderTimesheet;
          let activity: IActivityTimesheet;

          if(this.isOrder) {
            const userOrder: Partial<IOrder> = this.userOrders.find(({ id }) => id === orderId);
            order = {
              id: userOrder.id,
              name: userOrder.name,
              clientName: userOrder.clientName,
              distacco: userOrder.flagDistacco,
              oreLavorate: hours
            };
          } else {
            const userOrderActivity: ITypologyIT<OrderActivityTypeEnum, OrderActivityTypeIdEnum> = this.data.orderActivityTypeOptions.find(({ id }) => id === orderActivityId);
            activity = {
              id: userOrderActivity.id,
              nome: userOrderActivity.nome,
              code: userOrderActivity.code,
              descrizione: userOrderActivity.descrizione,
              oreLavorate: hours
            };
          }

          newWorkingDays.push({
            giorno: dayOfMonth,
            commessa: order,
            attivita: activity
          });
        });

        this.close({
          outcome: true,
          workingDays: newWorkingDays,
          excludedDays,
          isOrder: this.isOrder
        });
      } else {
        const { orderId, orderActivityId, hours } = this.form.getRawValue();
        let order: IOrderTimesheet;
        let activity: IActivityTimesheet;

        if(this.isOrder) {
          const userOrder: Partial<IOrder> = this.userOrders.find(({ id }) => id === orderId);
          order = {
            id: userOrder.id,
            name: userOrder.name,
            clientName: userOrder.clientName,
            distacco: userOrder.flagDistacco,
            oreLavorate: hours
          };
        } else {
          const userOrderActivity: ITypologyIT<OrderActivityTypeEnum, OrderActivityTypeIdEnum> = this.data.orderActivityTypeOptions.find(({ id }) => id === orderActivityId);
          activity = {
            id: userOrderActivity.id,
            nome: userOrderActivity.nome,
            code: userOrderActivity.code,
            descrizione: userOrderActivity.descrizione,
            oreLavorate: hours
          };
        }

        const workingDays: IFundedWorkingDayTimesheet[] = [
          {
            giorno: this.startDate().getDate(),
            commessa: order,
            attivita: activity
          }
        ];

        this.close({
          outcome: true,
          workingDays,
          isOrder: this.isOrder
        });
      }
    }
  }

  private _retrieveUserOrders(): void {
    this.userOrders = [];

    const { startDate, endDate } = this.form.getRawValue();
    if(!!startDate) {
      const start: number = (startDate as Date).getTime();
      const end: number = !!endDate ? (DateUtils.endOfDay(endDate as Date)).getTime() : undefined;

      forkJoin([
        this._orderService.getUserOrders(this.data.userId, start, end).pipe(
          catchError(() => of([]))
        ),
        this._orderService.getNonAccountableUserOrders(this.data.userId, start, end).pipe(
          catchError(() => of([]))
        ),
      ]).pipe(
        tap(([orders, nonAccountableOrders]) => {
          this.userOrders = [...this.userOrders]
            .concat(
              [...orders].filter(({ tipologiaCommessa, flagDistacco }) => [OrderTypeEnum.FUNDED_PROJECT, OrderTypeEnum.FUNDED_TRAINING].includes(tipologiaCommessa.codice) && !flagDistacco),
              [...nonAccountableOrders].filter(({ tipologiaCommessa, flagDistacco }) => [OrderTypeEnum.FUNDED_PROJECT, OrderTypeEnum.FUNDED_TRAINING].includes(tipologiaCommessa.codice) && !flagDistacco)
            );

          this.form.get('type').enable();

          this._cdr.markForCheck();
        })
      ).subscribe();
    } else {
      this._cdr.markForCheck();
    }
  }

  private _getDisabledDays(): Date[] {
    const holidays: Date[] = (this.data.holidays || []).map(({ giorno }) => new Date(this.data.currentYear, this.data.currentMonth, giorno));
    const noWorkingDays: Date[] = this._getNoWorkingDays();
    return [...holidays, ...noWorkingDays];
  }

  private _getNoWorkingDays(): Date[] {
    const currentDate: Date = new Date(this.data.currentYear, this.data.currentMonth, 1);

    const firstDateOfMonth: Date = DateUtils.firstDayOfMonth(currentDate);
    const lastDateOfMonth: Date = DateUtils.lastDayOfMonth(currentDate);

    const output: Date[] = [];
    for(let day: Date = firstDateOfMonth; day.getTime() <= lastDateOfMonth.getTime(); day.setDate(day.getDate() + 1)) {
      const dayOfMonth: number = day.getDate();

      if(!DateUtils.isWeekend(day)) {
        const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, day);
        const maxHours: number = workTime?.[day.getDay()] || 0;

        if(maxHours <= 0) output.push(new Date(day));
        else {
          const workingDay: IWorkingDayTimesheet<IOrderTimesheet> = this.data.workingDays
            .find(({ giorno }) => giorno === dayOfMonth);
          const workedDay: boolean = workingDay?.commesseOreTimesheet
            .some(({ code, distacco }) => [OrderTypeEnum.FUNDED_PROJECT, OrderTypeEnum.FUNDED_TRAINING].includes(code) || distacco);

          const overtimeDay: IWorkingDayTimesheet<IOvertimeTimesheet> = this.data.overtimes
            .find(({ giorno }) => giorno === dayOfMonth);
          const overtimedDay: boolean = !!overtimeDay?.commesseOreTimesheet?.length;

          const absenceDay: boolean = this.data.absences
            .some(({ giorno }) => giorno === dayOfMonth);

          const fundedWorkingDay: IFundedWorkingDayTimesheet = this.data.fundedWorkingDays
            .find(({ giorno }) => giorno === dayOfMonth);
          const fundedWorkedDay: boolean = !!fundedWorkingDay?.commessa?.oreLavorate || !!fundedWorkingDay?.attivita?.oreLavorate;

          if(workedDay || overtimedDay || absenceDay || fundedWorkedDay) output.push(new Date(day));
        }
      }
    }

    return output;
  }

}
