import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, Signal } from '@angular/core';
import { AbstractModal, ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { IDatepickerConfig, IModalInput, IModalOutput } from '@nesea/ngx-ui-kit/shared';
import { IAbsenceTimesheet, IOrderTimesheet, IOvertimeTimesheet, IWorkingDayTimesheet } from '@shared/models/interfaces/timesheet.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IWeekWorktime } from '@shared/models/interfaces/week-worktime.interface';
import { IHoliday } from '@shared/models/interfaces/holiday.interface';
import { DateUtils } from '@shared/utils/date.utils';
import { TimesheetUtils } from '@shared/utils/timesheet.utils';
import { IFundedWorkingDayTimesheet } from '@shared/models/interfaces/funded-timesheet.interface';
import { ITypology, ITypologyIT } from '@shared/models/interfaces/typology.interface';
import { FundedActivityTypeEnum } from '@shared/enums/funded-activity-type.enum';
import { translate } from '@jsverse/transloco';
import { OrderTypeEnum } from '@shared/enums/order-type.enum';
import { OrderActivityTypeEnum, OrderActivityTypeIdEnum } from '@shared/enums/order-activity-type.enum';

export interface IFundedTimesheetOrderDeleteModalInput extends IModalInput {
  userId: number;
  currentMonth: number;
  currentYear: number;
  orderActivityTypeOptions: ITypologyIT<OrderActivityTypeEnum, OrderActivityTypeIdEnum>[];
  workTime: IWeekWorktime[];
  holidays: IHoliday[];
  workingDays: IWorkingDayTimesheet<IOrderTimesheet>[];
  overtimes: IWorkingDayTimesheet<IOvertimeTimesheet>[];
  absences: IAbsenceTimesheet[];
  fundedWorkingDays: IFundedWorkingDayTimesheet[];
}
export interface IFundedTimesheetOrderDeleteModalOutput extends IModalOutput {
  outcome?: boolean;
  workingDays: IFundedWorkingDayTimesheet[];
  isOrder: boolean;
  isActivity: boolean;
  multiple: boolean;
}

@Component({
  selector: 'nsf-funded-timesheet-order-delete-modal',
  templateUrl: './funded-timesheet-order-delete-modal.component.html',
  styleUrl: './funded-timesheet-order-delete-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundedTimesheetOrderDeleteModalComponent extends AbstractModal<IFundedTimesheetOrderDeleteModalInput, IFundedTimesheetOrderDeleteModalOutput> {

  startDateConfig: IDatepickerConfig;
  endDateConfig: IDatepickerConfig;

  form: FormGroup;

  typeOptions: ITypology<FundedActivityTypeEnum>[] = [];
  userOrders: Partial<IOrderTimesheet>[] = [];
  userOrderActivities: ITypologyIT<OrderActivityTypeEnum, OrderActivityTypeIdEnum>[] = [];

  startDate: Signal<Date> = computed(() => this.form?.get('startDate')?.getRawValue() as Date);
  currentDate: Signal<Date> = computed(() => new Date(this.data.currentYear, this.data.currentMonth, 1));

  private _fb: FormBuilder = inject(FormBuilder);
  private _cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor(
    protected override modalViewerService: ModalViewerService
  ) {
    super(modalViewerService);
  }

  get multipleDays(): boolean {
    return !!this.form.get('showEndDate').getRawValue()
  }

  get isOrder(): boolean {
    return this.form.get('type').getRawValue() === FundedActivityTypeEnum.ORDER;
  }

  get isActivity(): boolean {
    return this.form.get('type').getRawValue() === FundedActivityTypeEnum.ACTIVITY;
  }

  override onInit(): void {
    this.typeOptions = [
      {
        id: 1,
        name: translate(`LABEL.${FundedActivityTypeEnum.ORDER}.TEXT`),
        code: FundedActivityTypeEnum.ORDER,
        description: translate(`LABEL.${FundedActivityTypeEnum.ORDER}.TEXT`)
      },
      {
        id: 2,
        name: translate(`LABEL.${FundedActivityTypeEnum.ACTIVITY}.TEXT`),
        code: FundedActivityTypeEnum.ACTIVITY,
        description: translate(`LABEL.${FundedActivityTypeEnum.ACTIVITY}.TEXT`)
      }
    ];

    this.startDateConfig = {
      adaptivePosition: true,
      minDate: DateUtils.firstDayOfMonth(this.currentDate()),
      maxDate: DateUtils.lastDayOfMonth(this.currentDate()),
      disableWeekends: true,
      daysDisabled: this._getDisabledDays()
    };

    this.endDateConfig = {
      adaptivePosition: true,
      maxDate: DateUtils.lastDayOfMonth(this.currentDate()),
      disableWeekends: true,
      daysDisabled: this._getDisabledDays()
    };

    this.form = this._fb.group({
      startDate: this._fb.control(null, Validators.required),
      endDate: this._fb.control({ value: null, disabled: true }, Validators.required),
      showEndDate: this._fb.control({ value: false, disabled: true }),
      type: this._fb.control({ value: null, disabled: true }, Validators.required),
      orderId: this._fb.control({ value: null, disabled: true }, Validators.required),
      orderActivityId: this._fb.control({ value: null, disabled: true }, Validators.required)
    });
  }

  override onDestroy(): void {}

  compareTypeOptionsFn = (option: FundedActivityTypeEnum, value: FundedActivityTypeEnum): boolean => option === value;

  compareOrderOptionsFn = (option: number, value: number): boolean => option === value;

  compareOrderActivityTypeOptionsFn = (option: number, value: number): boolean => option === value;

  onShowEndDateChange(value: boolean): void {
    this.endDateConfig = {
      adaptivePosition: true,
      maxDate: DateUtils.lastDayOfMonth(this.currentDate()),
      disableWeekends: true,
      daysDisabled: this._getDisabledDays()
    };

    this.form.get('type').reset();
    this.form.get('type').disable();

    this.form.get('orderId').reset();
    this.form.get('orderId').disable();
    this.form.get('orderActivityId').reset();
    this.form.get('orderActivityId').disable();

    if(!!value) {
      this.form.get('endDate').reset();
      this.form.get('endDate').enable();
      this.endDateConfig = {
        ...this.endDateConfig,
        minDate: !!this.form.get('startDate').value ? DateUtils.addDays(this.form.get('startDate').value, 1) : undefined
      };
    } else {
      this.form.get('endDate').disable();
      this.form.get('endDate').setValue(this.form.get('startDate').value);

      this._retrieveUserOrders();
    }
  }

  onStartDateChange(value: Date): void {
    this.form.get('endDate').reset();
    this.form.get('endDate').disable();

    this.form.get('showEndDate').setValue(false);

    if(!!value) {
      this.form.get('showEndDate').enable();
      this.form.get('endDate').setValue(value);
    } else {
      this.form.get('showEndDate').disable();
    }

    this.form.get('type').reset();
    this.form.get('type').disable();

    this.form.get('orderId').reset();
    this.form.get('orderId').disable();
    this.form.get('orderActivityId').reset();
    this.form.get('orderActivityId').disable();

    this._retrieveUserOrders();
  }

  onEndDateChange(value: Date): void {
    this.form.get('type').reset();
    this.form.get('type').disable();

    this.form.get('orderId').reset();
    this.form.get('orderId').disable();
    this.form.get('orderActivityId').reset();
    this.form.get('orderActivityId').disable();

    if(!!value) {
      this._retrieveUserOrders();
    }
  }

  onTypeChange(value: FundedActivityTypeEnum): void {
    this.form.get('orderId').reset();
    this.form.get('orderActivityId').reset();

    if(!value) {
      this.form.get('orderId').disable();
      this.form.get('orderActivityId').disable();
    } else if(value === FundedActivityTypeEnum.ORDER) {
      this.form.get('orderId').enable();
      this.form.get('orderActivityId').disable();
    } else {
      this.form.get('orderId').disable();
      this.form.get('orderActivityId').enable();
    }
  }

  onAbort(): void {
    this.close();
  }

  onConfirm(): void {
    if(this.form.invalid || (this.isOrder && !this.userOrders?.length) || (this.isActivity && !this.userOrderActivities?.length)) {
      this.form.markAllAsTouched();
    } else {
      const { startDate, endDate, orderId, orderActivityId }: { startDate: Date, endDate: Date, orderId: number[], orderActivityId: number[] } = this.form.getRawValue();
      const multiple: boolean = orderId?.length > 1 || orderActivityId?.length > 1;

      const newWorkingDays: IFundedWorkingDayTimesheet[] = this.data.fundedWorkingDays
        .filter(workingDay => {
          return this.isOrder ? orderId.includes(workingDay.commessa?.id) : orderActivityId.includes(workingDay.attivita?.id);
        })
        .filter(workingDay => DateUtils.daysBetween(startDate, endDate).some(({ dayOfMonth }) => dayOfMonth === workingDay.giorno))
        .map(workingDay => ({
          ...workingDay,
          commessa: undefined,
          attivita: undefined
        }));

      this.close({
        outcome: true,
        workingDays: [...newWorkingDays],
        isOrder: this.isOrder,
        isActivity: this.isActivity,
        multiple
      });
    }
  }

  private _retrieveUserOrders(): void {
    const { startDate, endDate } = this.form.getRawValue();
    const userOrders: Partial<IOrderTimesheet>[] = [];
    const userOrderActivities: ITypologyIT<OrderActivityTypeEnum, OrderActivityTypeIdEnum>[] = [];

    if(!!startDate) {
      this.data.fundedWorkingDays
        .filter(workingDay => DateUtils.daysBetween(startDate, endDate).some(({dayOfMonth}) => dayOfMonth === workingDay.giorno))
        .forEach(workingDay => {
          if(!!workingDay.commessa && !userOrders.some(({id}) => workingDay.commessa.id === id)) {
            userOrders.push(workingDay.commessa);
          }
          if(!!workingDay.attivita && !userOrderActivities.some(({ id }) => workingDay.attivita.id === id)) {
            userOrderActivities.push(workingDay.attivita);
          }
        });

      this.form.get('type').enable();
    }

    this.userOrders = [...userOrders];
    this.userOrderActivities = [...userOrderActivities];

    this._cdr.markForCheck();
  }

  private _getDisabledDays(): Date[] {
    const holidays: Date[] = (this.data.holidays || []).map(({ giorno }) => new Date(this.data.currentYear, this.data.currentMonth, giorno));
    const noWorkingDays: Date[] = this._getNoWorkingDays();
    return [...holidays, ...noWorkingDays];
  }

  private _getNoWorkingDays(): Date[] {
    const currentDate: Date = new Date(this.data.currentYear, this.data.currentMonth, 1);

    const firstDateOfMonth: Date = DateUtils.firstDayOfMonth(currentDate);
    const lastDateOfMonth: Date = DateUtils.lastDayOfMonth(currentDate);

    const output: Date[] = [];
    DateUtils.daysBetween(firstDateOfMonth, lastDateOfMonth)
      .forEach(({ dayOfMonth, dayOfWeek, date }) => {

      if(!DateUtils.isWeekend(date)) {
        const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, date);
        const maxHours: number = workTime?.[dayOfWeek] || 0;

        if(maxHours <= 0) output.push(new Date(date));
        else {
          const workingDay: IWorkingDayTimesheet<IOrderTimesheet> = this.data.workingDays
            .find(({ giorno }) => giorno === dayOfMonth);
          const workedDay: boolean = workingDay?.commesseOreTimesheet
            .some(({ code, distacco }) => [OrderTypeEnum.FUNDED_PROJECT, OrderTypeEnum.FUNDED_TRAINING].includes(code) || distacco);

          const overtimeDay: IWorkingDayTimesheet<IOvertimeTimesheet> = this.data.overtimes
            .find(({ giorno }) => giorno === dayOfMonth);
          const overtimedDay: boolean = !!overtimeDay?.commesseOreTimesheet?.length;

          const absenceDay: boolean = this.data.absences
            .some(({ giorno }) => giorno === dayOfMonth);

          if(workedDay || overtimedDay || absenceDay) output.push(new Date(date));
        }
      }
    });

    return output;
  }

}
