import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, Signal } from '@angular/core';
import { AbstractModal, ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { IDatepickerConfig, IModalInput, IModalOutput } from '@nesea/ngx-ui-kit/shared';
import { IAbsenceTimesheet, IOrderTimesheet, IWorkingDayTimesheet } from '@shared/models/interfaces/timesheet.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateUtils } from '@shared/utils/date.utils';
import { OrdersService } from '@shared/services/orders.service';
import { take, tap } from 'rxjs';
import { IOrder } from '@shared/models/interfaces/order.interface';
import { IWeekWorktime } from '@shared/models/interfaces/week-worktime.interface';
import { IHoliday } from '@shared/models/interfaces/holiday.interface';
import { TimesheetUtils } from '@shared/utils/timesheet.utils';

export interface ITimesheetOrderUpdateModalInput extends IModalInput {
  userId: number;
  currentMonth: number;
  currentYear: number;
  workTime: IWeekWorktime[];
  holidays: IHoliday[];
  workingDays: IWorkingDayTimesheet<IOrderTimesheet>[];
  absences: IAbsenceTimesheet[];
  startDate?: Date;
  order?: IOrderTimesheet;
}
export interface ITimesheetOrderUpdateModalOutput extends IModalOutput {
  outcome?: boolean;
  remove?: boolean;
  workingDays: IWorkingDayTimesheet<Partial<IOrderTimesheet>>[];
  excludedDays?: number[];
}

@Component({
  selector: 'nsf-timesheet-order-update-modal',
  templateUrl: './timesheet-order-update-modal.component.html',
  styleUrl: './timesheet-order-update-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimesheetOrderUpdateModalComponent extends AbstractModal<ITimesheetOrderUpdateModalInput, ITimesheetOrderUpdateModalOutput> {

  startDateConfig: IDatepickerConfig;
  endDateConfig: IDatepickerConfig;

  form: FormGroup;

  userOrders: Partial<IOrder>[] = [];
  visibleHours: number[] = [];
  visibleMinutes: number[] = [30];

  startDate: Signal<Date> = computed(() => this.form?.get('startDate')?.getRawValue() as Date);
  currentDate: Signal<Date> = computed(() => new Date(this.data.currentYear, this.data.currentMonth, 1));

  constructor(
    protected override modalViewerService: ModalViewerService,
    private _fb: FormBuilder,
    private _orderService: OrdersService,
    private _cdr: ChangeDetectorRef
  ) {
    super(modalViewerService);
  }

  get isEdit(): boolean {
    return !!this.data.order;
  }

  get multipleDays(): boolean {
    return !!this.form.get('showEndDate').getRawValue();
  }

  override onInit(): void {
    this.startDateConfig = {
      adaptivePosition: true,
      minDate: DateUtils.firstDayOfMonth(this.currentDate()),
      maxDate: DateUtils.lastDayOfMonth(this.currentDate()),
      disableWeekends: true,
      daysDisabled: this._getDisabledDays()
    };

    this.endDateConfig = {
      adaptivePosition: true,
      maxDate: DateUtils.lastDayOfMonth(this.currentDate()),
      disableWeekends: true,
      daysDisabled: this._getDisabledDays()
    };

    this.form = this._fb.group({
      startDate: this._fb.control({ value: this.data.startDate, disabled: !!this.data.startDate }, Validators.required),
      endDate: this._fb.control({ value: this.data.startDate, disabled: true }, Validators.required),
      showEndDate: this._fb.control({ value: false, disabled: !this.data.startDate || (!!this.data.startDate && DateUtils.sameDate(this.data.startDate, DateUtils.lastDayOfMonth(this.data.startDate))) || !!this.data.order }),
      orderName: this._fb.control({ value: null, disabled: true }, Validators.required),
      customer: this._fb.control({ value: null, disabled: true }),
      hours: this._fb.control({ value: null, disabled: !this.data.order }, Validators.required),
      minutes: this._fb.control({ value: null, disabled: !this.data.order })
    });

    if(!!this.data.order) {
      this.userOrders = [{
        id: this.data.order.id,
        name: this.data.order.name,
        clientName: this.data.order.clientName
      }];

      const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, this.data.startDate);

      const maxHours: number = workTime?.[this.data.startDate.getDay()] || 0;
      const workedHours: number = this.data.workingDays
        .find(order => order.giorno === this.data.startDate.getDate()).commesseOreTimesheet
        .filter(order => order.id !== this.data.order.id)
        .reduce((output, current) => output + current.oreLavorate, 0);
      const absenceHours: number = this.data.absences
        .filter(({ giorno }) => giorno === this.startDate().getDate())
        .reduce((output, current) => output + current.ore, 0);
      const leftHours: number = maxHours - (workedHours + absenceHours);
      const roundHours: number = Math.floor(leftHours);
      if(roundHours === 0) {
        this.visibleHours = [0];
      } else {
        this.visibleHours = [ ...Array.from(Array(roundHours).keys()), roundHours];
      }

      const hours: number = Math.floor(this.data.order.oreLavorate);
      const minutes: number = this.data.order.oreLavorate - hours > 0 ? 30 : 0;

      this.form.get('orderName').setValue(this.data.order.id);
      this.form.get('customer').setValue(this.data.order.clientName);
      this.form.get('hours').setValue(hours);
      this.form.get('minutes').setValue(minutes);
    } else {
      this._retrieveUserOrders();
    }
  }

  override onDestroy(): void {}

  compareOrderOptionsFn = (option: number, value: number): boolean => option === value;

  compareNumbersFn = (option: number, value: number): boolean => option === value;

  onStartDateChange(value: Date): void {
    this.form.get('endDate').reset();
    this.form.get('endDate').disable();

    this.form.get('showEndDate').setValue(false);

    if(!!value) {
      this.form.get('showEndDate').enable();
      this.form.get('endDate').setValue(value);
    } else {
      this.form.get('showEndDate').disable();
    }

    this.form.get('orderName').reset();
    this.form.get('orderName').disable();
    this.form.get('customer').reset();

    this.form.get('hours').reset();
    this.form.get('hours').disable();
    this.form.get('minutes').reset();
    this.form.get('minutes').disable();

    this._retrieveUserOrders();
  }

  onEndDateChange(value: Date): void {
    this.form.get('orderName').reset();
    this.form.get('orderName').disable();
    this.form.get('customer').reset();

    this.form.get('hours').reset();
    this.form.get('hours').disable();
    this.form.get('minutes').reset();
    this.form.get('minutes').disable();

    if(!!value) {
      this._retrieveUserOrders();
    }
  }

  onShowEndDateChange(value: boolean): void {
    this.endDateConfig = {
      adaptivePosition: true,
      maxDate: DateUtils.lastDayOfMonth(this.currentDate()),
      disableWeekends: true,
      daysDisabled: this._getDisabledDays()
    };

    this.form.get('orderName').reset();
    this.form.get('orderName').disable();
    this.form.get('customer').reset();

    this.form.get('hours').reset();
    this.form.get('hours').disable();
    this.form.get('minutes').reset();
    this.form.get('minutes').disable();

    if(!!value) {
      this.form.get('endDate').reset();
      this.form.get('endDate').enable();
      this.endDateConfig = {
        ...this.endDateConfig,
        minDate: !!this.form.get('startDate').value ? DateUtils.addDays(this.form.get('startDate').value, 1) : undefined
      };
    } else {
      this.form.get('endDate').disable();
      this.form.get('endDate').setValue(this.form.get('startDate').value);

      this._retrieveUserOrders();
    }
  }

  onOrderChange(value: number): void {
    if(!value) {
      this.form.get('customer').reset();
      this.form.get('hours').reset();
      this.form.get('minutes').reset();
    } else this.form.get('customer').setValue(this.userOrders.find(({ id }) => id === value).clientName);
  }

  onHoursChange(value: number): void {
    this.form.get('minutes').reset();
    this.form.get('minutes').clearValidators();

    if(!!value || value === 0) {
      if(value === 0) {
        this.form.get('minutes').setValidators(Validators.required);
      }

      if(!!this.multipleDays) {
        value === 8 ? this.form.get('minutes').disable() : this.form.get('minutes').enable();
      } else {
        const { startDate } = this.form.getRawValue();
        const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, startDate as Date);
        const maxHours: number = workTime?.[(startDate as Date).getDay()] || 0;
        let workedHours: number;
        if(!this.data.order) {
          const workedOrders: IOrderTimesheet[] = (this.data.workingDays
            .find(order => order.giorno === this.startDate().getDate())?.commesseOreTimesheet) || [];
          workedHours = workedOrders.reduce((output, current) => output + current.oreLavorate, 0);
        } else {
          workedHours = this.data.workingDays
            .find(order => order.giorno === this.startDate().getDate()).commesseOreTimesheet
            .filter(order => order.id !== this.data.order.id)
            .reduce((output, current) => output + current.oreLavorate, 0);
        }
        const absenceHours: number = this.data.absences
          .filter(({ giorno }) => giorno === this.startDate().getDate())
          .reduce((output, current) => output + current.ore, 0);
        const leftHours: number = maxHours - (workedHours + absenceHours);

        if(leftHours - value > 0) {
          this.form.get('minutes').enable();
        } else {
          this.form.get('minutes').disable();
        }
      }
    } else {
      this.form.get('minutes').disable();
    }
  }

  onConfirm(): void {
    if(this.form.invalid || !this.userOrders?.length) {
      this.form.markAllAsTouched();
    } else {
      if(!!this.multipleDays) {
        const { startDate, endDate }: { startDate: Date, endDate: Date } = this.form.getRawValue();
        const daysBetween: { dayOfMonth: number, dayOfWeek: number, date: Date }[] = DateUtils.daysBetween(startDate, endDate);

        const orderId: number = this.form.get('orderName').value;
        const includedDays: number[] = [];
        const excludedDays: number[] = [];

        daysBetween
          .filter(({ dayOfMonth }) => !this.data.holidays.some(({ giorno }) => dayOfMonth === giorno))
          .forEach(({ dayOfWeek, dayOfMonth, date }) => {
          const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, date);
          const maxHours: number = workTime?.[dayOfWeek] || 0;

          // Giorno non previsto nel contratto
          if(!maxHours) {
            excludedDays.push(dayOfMonth);
          } else {
            const absenceHours: number = this.data.absences
              .filter(({ giorno }) => giorno === dayOfMonth)
              .reduce((output, current) => output + current.ore, 0);

            const workingDay: IWorkingDayTimesheet<IOrderTimesheet> = this.data.workingDays.find(({ giorno }) => giorno === dayOfMonth);
            const workedHours: number = !!workingDay ?
              workingDay.commesseOreTimesheet.filter(order => order.id !== orderId).reduce((output, current) => output + current.oreLavorate, 0) :
              0;
            const leftHours: number = maxHours - (absenceHours + workedHours);

            const orderHours: number = this.form.get('hours').getRawValue();
            const orderMinutes: number = (this.form.get('minutes').getRawValue() || 0) / 60;
            const totalHours: number = orderHours + orderMinutes;

            // Giorno nel quale le ore residue sono inferiori a quelle da consuntivare
            if(totalHours > leftHours) {
              excludedDays.push(dayOfMonth);
            } else {
              includedDays.push(dayOfMonth);
            }
          }
        });

        const newWorkingDays: IWorkingDayTimesheet<IOrderTimesheet>[] = [];
        includedDays.forEach(day => {
          const oldOrders: IOrderTimesheet[] = this.data.workingDays.find(({ giorno }) => day === giorno)?.commesseOreTimesheet || [];
          const newOrders: IOrderTimesheet[] = [...oldOrders]
            .filter(order => order.id !== orderId);

          const userOrder: Partial<IOrder> = this.userOrders.find(order => order.id === orderId);
          const hours: number = this.form.get('hours').getRawValue();
          const minutes: number = (this.form.get('minutes').getRawValue() || 0) / 60;
          newOrders.push({
            id: orderId,
            name: userOrder.name,
            clientName: userOrder.clientName,
            oreLavorate: hours + minutes,
            distacco: userOrder.flagDistacco
          });

          newWorkingDays.push({
            giorno: day,
            commesseOreTimesheet: newOrders
          });
        });

        this.close({
          outcome: true,
          workingDays: newWorkingDays,
          excludedDays
        });
      } else {
        let newOrders: IOrderTimesheet[];
        if(!!this.data.order) {
          newOrders = [...this.data.workingDays]
            .find(order => order.giorno === this.startDate().getDate()).commesseOreTimesheet
            .filter(order => order.id !== this.data.order.id);
        } else {
          newOrders = ([...this.data.workingDays].find(order => order.giorno === this.startDate().getDate())?.commesseOreTimesheet) || [];
        }
        const orderId: number = this.form.get('orderName').getRawValue();
        const userOrder: Partial<IOrder> = this.userOrders.find(order => order.id === orderId);
        const hours: number = this.form.get('hours').getRawValue();
        const minutes: number = (this.form.get('minutes').getRawValue() || 0) / 60;
        newOrders.push({
          id: orderId,
          name: userOrder.name,
          clientName: userOrder.clientName,
          oreLavorate: hours + minutes,
          distacco: userOrder.flagDistacco
        });

        this.close({
          outcome: true,
          workingDays: [
            {
              giorno: this.startDate().getDate(),
              commesseOreTimesheet: newOrders
            }
          ]
        });
      }
    }
  }

  onAbort(): void {
    this.close();
  }

  onRemove(): void {
    const newOrders: IOrderTimesheet[] = [...this.data.workingDays]
      .find(order => order.giorno === this.startDate().getDate()).commesseOreTimesheet
      .filter(order => order.id !== this.data.order.id);
    this.close({
      outcome: true,
      remove: true,
      workingDays: [
        {
          giorno: this.startDate().getDate(),
          commesseOreTimesheet: newOrders
        }
      ]
    });
  }

  private _retrieveUserOrders(): void {
    const { startDate, endDate } = this.form.getRawValue();
    if(!!startDate) {
      const start: number = (startDate as Date).getTime();
      const end: number = !!endDate ? (DateUtils.endOfDay(endDate as Date)).getTime() : undefined;

      this._orderService.getUserOrders(this.data.userId, start, end).pipe(
        take(1),
        tap(orders => {
          if(!!this.multipleDays) {
            this.userOrders = [...orders];
          } else {
            this.userOrders = [...orders]
              .filter(order => {
                const workingDay: IWorkingDayTimesheet<IOrderTimesheet> = this.data.workingDays.find(order => order.giorno === this.startDate().getDate());
                if(!!workingDay) {
                  return (workingDay.commesseOreTimesheet || []).every(workedOrders => workedOrders.id !== order.id)
                } else {
                  return true;
                }
              });
          }
          this.form.get('orderName').enable();
          this.form.get('hours').enable();

          const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, startDate as Date);
          const maxHours: number = workTime?.[(startDate as Date).getDay()] || 0;
          if(!!this.multipleDays) {
            this.visibleHours = [ ...Array.from(Array(maxHours).keys()), maxHours];
          } else {
            const workedOrders: IOrderTimesheet[] = (this.data.workingDays
              .find(({ giorno }) => giorno === this.startDate().getDate())?.commesseOreTimesheet) || [];
            const workedHours: number = workedOrders.reduce((output, current) => output + current.oreLavorate, 0);
            const absenceHours: number = this.data.absences
              .filter(({ giorno }) => giorno === this.startDate().getDate())
              .reduce((output, current) => output + current.ore, 0);
            const leftHours: number = maxHours - (workedHours + absenceHours);
            const roundHours: number = Math.floor(leftHours);
            if(roundHours === 0) {
              this.visibleHours = [0];
            } else {
              this.visibleHours = [ ...Array.from(Array(roundHours).keys()), roundHours];
            }
          }

          this._cdr.markForCheck();
        })
      ).subscribe()
    } else {
      this.userOrders = [];

      this.form.get('orderName').reset();
      this.form.get('orderName').disable();
      this.form.get('customer').reset();

      this.form.get('hours').reset();
      this.form.get('hours').disable();
      this.form.get('minutes').reset();
      this.form.get('minutes').disable();

      this._cdr.markForCheck();
    }
  }

  private _getDisabledDays(): Date[] {
    const holidays: Date[] = (this.data.holidays || []).map(({ giorno }) => new Date(this.data.currentYear, this.data.currentMonth, giorno));
    const noWorkingDays: Date[] = this._getNoWorkingDays();
    return [...holidays, ...noWorkingDays];
  }

  private _getNoWorkingDays(): Date[] {
    const currentDate: Date = new Date(this.data.currentYear, this.data.currentMonth, 1);

    const firstDateOfMonth: Date = DateUtils.firstDayOfMonth(currentDate);
    const lastDateOfMonth: Date = DateUtils.lastDayOfMonth(currentDate);

    const output: Date[] = [];
    for(let day: Date = firstDateOfMonth; day.getTime() <= lastDateOfMonth.getTime(); day.setDate(day.getDate() + 1)) {
      const dayOfMonth: number = day.getDate();

      if(!DateUtils.isWeekend(day)) {
        const workTime: IWeekWorktime = TimesheetUtils.getWorktimeByDate(this.data.workTime, day);
        const maxHours: number = workTime?.[day.getDay()] || 0;

        const workingDay: IWorkingDayTimesheet<IOrderTimesheet> = this.data.workingDays.find(({ giorno }) => giorno === dayOfMonth);
        const workedHours: number = !!workingDay ? workingDay.commesseOreTimesheet.reduce((output, current) => output + current.oreLavorate, 0) : 0;

        const absenceHours: number = this.data.absences
          .filter(({ giorno }) => giorno === dayOfMonth)
          .reduce((output, current) => output + current.ore, 0);

        const leftHours: number = maxHours - (absenceHours + workedHours);

        if(leftHours <= 0) output.push(new Date(day));
      }
    }

    return output;
  }

}
