import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '@core/models/interfaces/response.interface';
import { map, Observable } from 'rxjs';
import { IOrder } from '@shared/models/interfaces/order.interface';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private _httpClient: HttpClient = inject(HttpClient);

  getUserOrders(userId: number, startDate: number, endDate?: number): Observable<IOrder[]> {
    const body = {
      user: userId,
      startDate,
      endDate
    }
    return this._httpClient.post<IResponse<IOrder[]>>(`/commesse/byUserInRange`, body).pipe(
      map(({ data }) => data)
    );
  }

  getUserActivations(userId: number, startDate: number, endDate?: number): Observable<IOrder[]> {
    const body = {
      user: userId,
      startDate,
      endDate
    }
    return this._httpClient.post<IResponse<IOrder[]>>(`/commesse/activationInfo`, body).pipe(
      map(({ data }) => data)
    );
  }

  getNonAccountableUserOrders(userId: number, startDate: number, endDate?: number): Observable<IOrder[]> {
    const body = {
      user: userId,
      startDate,
      endDate
    }
    return this._httpClient.post<IResponse<IOrder[]>>(`/commesse/byUserInRangeNoConsuntivo`, body).pipe(
      map(({ data }) => data)
    );
  }

}
