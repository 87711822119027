import { catchError, forkJoin, map, Observable, tap, throwError } from 'rxjs';
import { FileType, MimeType } from '@shared/models/types/file.type';

const getMimeTypeFromBase64 = (base64: string): MimeType => {
  const map: Map<string, MimeType> = new Map([
    ['/9j/', 'image/jpeg'],
    ['iVBORw', 'image/png'],
    ['JVBERi0', 'application/pdf']
  ]);

  let output: MimeType;
  for(const [prefix, mimeType] of map) {
    if(base64.startsWith(prefix)) {
      output = mimeType;
      break;
    }
  }

  return output;
};

const getMimeTypeFromFileExtension = (extension: FileType): MimeType => {
  const map: Map<FileType, MimeType> = new Map([
    ['.pdf', 'application/pdf'],
    ['.png', 'image/png'],
    ['.jpg', 'image/jpeg'],
    ['.jpeg', 'image/jpeg']
  ]);

  return map.get(extension);
};

const getFileName = (contentDisposition: string) => {
  if(!contentDisposition) return null;
  else return decodeURI(contentDisposition?.match(/(?<=filename(?:=|\*=(?:[\w\-]+'')))["']?(?<filename>[^"';\n]+)["']?/g)?.pop()?.replace(/\"/g, ''));
};

const readFileB64 = (file: File | Blob, includeMimeType: boolean = true) => new Observable<string | null>(subscriber => {
  if(!file) {
    subscriber.next('');
    subscriber.complete();
    return;
  }

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const result = reader.result as string;
    !includeMimeType ? subscriber.next(result.substring(result.indexOf(',') + 1)) : subscriber.next(result);
    subscriber.complete();
  };
  reader.onerror = error => subscriber.error(error);
});

const readFileByteArray = (file: File, origin: boolean = false) => new Observable<Uint8Array | { file: File, byteArray: Uint8Array } | null>(subscriber => {
  if(!file) {
    subscriber.next(null);
    subscriber.complete();
    return;
  }

  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onload = () => {
    const arrayBuffer: ArrayBuffer = reader.result as ArrayBuffer;
    const result: Uint8Array = new Uint8Array(arrayBuffer);
    if(!!origin) {
      subscriber.next({ file, byteArray: result });
    } else {
      subscriber.next(result);
    }
    subscriber.complete();
  };
  reader.onerror = error => subscriber.error(error);
});

const readFilesByteArray = (files: File[], origin: boolean = false) => new Observable<(Uint8Array | { file: File, byteArray: Uint8Array })[] | null>(subscriber => {
  forkJoin(files.map(file => readFileByteArray(file, origin))).pipe(
    tap(res => {
      subscriber.next(res);
      subscriber.complete();
    }),
    catchError(err => {
      subscriber.error(err);
      return throwError(() => err);
    })
  ).subscribe();
});

const readFile = (file: File, origin: boolean = false) => new Observable<string | { file: File, text: string } | null>(subscriber => {
  if(!file) {
    subscriber.next('');
    subscriber.complete();
    return;
  }

  const reader = new FileReader();
  reader.readAsText(file);
  reader.onload = () => {
    const result = reader.result as string;
    if(!!origin) {
      subscriber.next({ file, text: result });
    } else {
      subscriber.next(result);
    }
    subscriber.complete();
  };
  reader.onerror = error => subscriber.error(error);
});

const readFiles = (files: File[], origin: boolean = false) => new Observable<(string | { file: File, text: string })[] | null>(subscriber => {
  forkJoin(files.map(file => readFile(file, origin))).pipe(
    tap(res => {
      subscriber.next(res);
      subscriber.complete();
    }),
    catchError(err => {
      subscriber.error(err);
      return throwError(() => err);
    })
  ).subscribe();
});

const addMimeTypeToBlob = (file: File | Blob): Observable<Blob> => {
  return readFileB64(file, false).pipe(
    map(base64 => getMimeTypeFromBase64(base64)),
    map(mimeType => new Blob([file], { type: mimeType }))
  );
};

export const FileUtils = {
  getMimeTypeFromBase64,
  getMimeTypeFromFileExtension,
  getFileName,
  readFileB64,
  readFileByteArray,
  readFilesByteArray,
  readFile,
  readFiles,
  addMimeTypeToBlob
};
