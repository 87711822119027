import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ITime } from '@nesea/ngx-ui-kit/shared';
import { FileType, MimeType } from '@shared/models/types/file.type';
import { FileUtils } from '@shared/utils/file.utils';

const maybeRequiredValidator = (
  inputName: string,
  requiredWhen: (form: AbstractControl) => boolean ): ValidatorFn =>
  (form: AbstractControl): ValidationErrors | null => {
    let targetInput = form.get(inputName);
    if (targetInput) {
      let isRequired = requiredWhen(form);
      if (isRequired != targetInput.hasValidator(Validators.required)) {
        if (isRequired) {
          targetInput.addValidators(Validators.required);
        } else {
          targetInput.removeValidators(Validators.required);
        }
        targetInput.updateValueAndValidity({ onlySelf: true });
      }
    }
    return null;
  };

const dateTimepickerValidator = (): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  if(!control.value) return null;
  else {
    const minutes: number = (control.value as ITime).minutes;
    return minutes % 30 !== 0 ? { dateTime: true } : null;
  }
};

const fileTypeValidator = (acceptedFileTypes: FileType[], multiple?: boolean): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  if(!control.value) return null;
  else {
    const mimeTypes: MimeType[] = [];
    acceptedFileTypes.forEach(fileType => mimeTypes.push(FileUtils.getMimeTypeFromFileExtension(fileType)));

    if(multiple) {
      const files: File[] = control.value;
      if(!!files?.length && !files.every(file => mimeTypes.includes(file.type as MimeType))) return { fileType: true };
      else return null;
    } else {
      const { type: mimeType } = control.value as File;
      if(!mimeTypes.includes(mimeType as MimeType)) return { fileType: true };
      else return null;
    }
  }
};

export const ValidatorUtils = {
  maybeRequiredValidator,
  dateTimepickerValidator,
  fileTypeValidator
};
