<ng-container *transloco="let transloco">
  <nsa-modal>
    <ng-container modal-body>
      <p class="text-center">{{ transloco(!!data.message ? data.message : 'MODAL.CONFIRM.MESSAGE', data.translateParams) }}</p>
        @if(!!data?.showNote) {
          <div>
            <nsa-textarea
              nsaId="nsf-confirm-note-id"
              nsaName="nsf-confirm-note-name"
              class="text-center"
              size="md"
              label="LABEL.NOTE.TEXT"
              placeholder="LABEL.NOTE.PLACEHOLDER"
              [(ngModel)]="note">
            </nsa-textarea>
          </div>
        }
    </ng-container>

    <ng-container modal-footer>
      <nsa-button
        nsaId="nsf-cancel-button"
        type='button'
        icon="x-lg"
        color="dark"
        iconColor="danger"
        (click)="onAbort()">
        {{ transloco('ACTION.ABORT') }}
      </nsa-button>

      <nsa-button
        nsaId="nsf-confirm-button"
        type='submit'
        icon="check-lg"
        color="dark"
        iconColor="success"
        (click)="onConfirm()">
        {{ transloco('ACTION.CONFIRM') }}
      </nsa-button>
    </ng-container>
  </nsa-modal>
</ng-container>
